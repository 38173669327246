import React from "react";
import { Link } from "gatsby";
import * as moment from "moment";
import BackgroundImage from "gatsby-background-image";
import kebabCase from "lodash.kebabcase";

function BlogItem(props) {
  const {
    index,
    slug,
    title,
    thumbnail,
    author: authorDetails,
    date,
    excerpt,
    tags,
  } = props;
  // Parse stringified author details from relational
  const { name, thumbnail: authorThumbnail } = authorDetails;
  return (
    <Link
      className={`card${
        tags && tags.length
          ? " " + [...tags.map(tag => kebabCase(tag))].join(" ")
          : ""
      }`}
      to={slug}
    >
      {thumbnail && (
        <BackgroundImage
          className="card__image"
          fluid={thumbnail.childImageSharp.fluid}
          style={{
            display: index % 2 !== 0 ? "none" : "block",
          }}
        />
      )}

      <div
        className={
          "card__content--compact" + ` ${index % 2 !== 0 ? "pt-0" : ""}`
        }
      >
        {authorDetails && authorThumbnail && name ? (
          <div className="card__author">
            <div
              className="card__author-image"
              style={{
                backgroundImage: `url('${authorThumbnail}')`,
              }}
            ></div>
            <span>{name}</span>
          </div>
        ) : null}
        <div className="card__date">{moment(date).format("ll")}</div>
        <h5 className="card__title">{title}</h5>
        <div className="card__description">{excerpt}</div>
      </div>
    </Link>
  );
}

export default BlogItem;
