import { Link } from "gatsby";
import React, {
  Component,
  PropsWithChildren,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import * as moment from "moment";
import * as Isotope from "isotope-layout";
import BlogItem from "./blog-item";
import { PrimaryButton } from "../buttons/primary-button";
import { FadeIn, FadeUp } from "../shared/transitions";
import { PoseGroup } from "react-pose";
import kebabCase from "lodash.kebabcase";

interface IBlogListing {
  title: string;
  thumbnail: string;
  date: Date;
  description: string;
  author: string;
  excerpt: string;
  tags: string[];
  postType: "default" | "feature";
  seo: ISEO;
}

interface ISEO {
  titleMeta: string;
  descriptionMeta: string;
  summaryMeta: string;
  keywordMeta: string[];
}

interface IBlogListingProps {
  blogPosts: INode<IBlogListing>[];
  selectedFilters: string[];
  setSelectedFilters: (filters: string[]) => void;
  tags: ITag[];
}

interface ITag {
  node: {
    name: string;
  };
}

interface INode<Type> {
  node: {
    childMdx: {
      slug: string;
      frontmatter: Type;
    };
  };
}

interface IImage {}

function blogListing({
  blogPosts,
  tags,
  selectedFilters,
  setSelectedFilters,
}: PropsWithChildren<IBlogListingProps>) {
  const [featurePost, setFeaturePost] = useState<INode<IBlogListing>>();
  const [tagFilters, setTagFilters] = useState<
    { name: string; active: boolean; key: string }[]
  >();
  const [allFilterAdded, setAllFilterAdded] = useState<boolean>(false);
  const [loadedPosts, setLoadedPosts] = useState<INode<IBlogListing>>(
    blogPosts
  );

  const [grid, setGrid] = useState<Isotope>();
  const gridRef = useRef<Ref<HTMLDivElement>>();

  useEffect(() => {
    // const posts = blogPosts.splice(0, )
    console.log("tags", tagFilters);
    findFeaturePost();
    initTagFilters();

    if (window && typeof window !== "undefined") {
      if (!grid) {
        setGrid(
          new Isotope(gridRef.current, {
            layoutMode: "masonry",
            itemSelector: ".card",
            masonary: {
              columnWidth: ".item-sizer",
              gutter: 25,
            },
            percentPosition: true,
          })
        );
      } else {
        window.onload = () => {
          grid.arrange();
        };
      }
    }
  }, [grid]);

  const initTagFilters = () => {
    if (!tags) {
      return;
    }
    console.log("tags", tags);
    console.log("blogPosts", blogPosts);
    console.log("tagFilters", tagFilters);
    const tagState = tags.map(({ node: { name, active } }) => {
      const formattedName = name.replace("-", " ");
      return {
        label: formattedName,
        name,
        active: active || false,
      };
    });
    setTagFilters(tagState);
  };

  const findFeaturePost = () => {
    const featurePostNode = blogPosts.find(
      ({ node }) => node.postType && node.postType === "feature"
    );
    setFeaturePost(featurePostNode);
  };

  const filterByTag = (tag: string) => {
    let selectedState;
    let filtersUpdated = [];
    if (!tag || tag === "all") {
      selectedState = [];
      filtersUpdated = [...tagFilters].map(filter => ({
        ...filter,
        active: false,
      }));
      setTagFilters(filtersUpdated);
    } else {
      const filterKey = kebabCase(tag);

      if (!selectedFilters.includes(filterKey)) {
        selectedState = [...selectedFilters, filterKey];
      } else {
        const filterIndex = selectedFilters.indexOf(filterKey);
        selectedState = [...selectedFilters];
        selectedState.splice(filterIndex, 1);
      }
    }
    let tagFiltersCopy = [];

    tagFiltersCopy = filtersUpdated.length
      ? [...filtersUpdated]
      : [...tagFilters];

    if (tagFilters.length > 1 && selectedState.length > 0 && !allFilterAdded) {
      tagFiltersCopy.unshift({
        label: "All",
        name: "all",
        active: false,
      });
      setTagFilters(tagFiltersCopy);
      setAllFilterAdded(true);
    } else if (allFilterAdded && selectedState.length < 1) {
      tagFiltersCopy.shift();
      setTagFilters(tagFiltersCopy);
      setAllFilterAdded(false);
    }

    setSelectedFilters(state => {
      arrangeGrid(selectedState);
      return selectedState;
    });
  };

  const arrangeGrid = arrangeState => {
    if (arrangeState && arrangeState.length) {
      const filters = "." + arrangeState.join(".");
      grid.arrange({ filter: filters });
    } else {
      grid.arrange({ filter: "*" });
    }
  };

  const loadMorePosts = () => {
    setLoadedPosts([...loadedPosts, ...loadedPosts]);
    grid.reloadItems();
    grid.arrange();
  };

  const renderFeaturePost = () => {
    if (!featurePost) {
      return null;
    }
    const {
      slug,
      title,
      thumbnail,
      author: authorDetails,
      date,
      excerpt,
    } = featurePost.node;
    const { name, thumbnail: authorThumbnail } = authorDetails;
    return (
      <div className="card__container">
        <PoseGroup animateOnMount enterPose="enter" exitPose="exit">
          <FadeIn key="fadeFeatureIn" style={{ position: "relative" }}>
            <Link className="card--feature" to={slug}>
              <div
                className="card__image"
                style={{
                  backgroundImage: `url('${thumbnail.childImageSharp.fluid.src}')`,
                }}
              ></div>
              <div className="card__content align-items-start">
                <div className="d-flex justify-content-between w-100">
                  {authorDetails && authorThumbnail && name ? (
                    <div className="card__author pt-0">
                      <div
                        className="card__author-image"
                        style={{
                          backgroundImage: `url('${authorThumbnail}')`,
                        }}
                      ></div>
                      <span>{name}</span>
                    </div>
                  ) : null}
                  <div className="card__date">{moment(date).format("ll")}</div>
                </div>
                <h5 className="card__title">{title}</h5>
                <div className="card__description">{excerpt}</div>
                <PrimaryButton>View Article</PrimaryButton>
              </div>
            </Link>
          </FadeIn>
        </PoseGroup>
      </div>
    );
  };

  const renderTagFilters = () => {
    if (!tagFilters) {
      return;
    }

    return (
      <div className="tag-list">
        {tagFilters.map((tag, i) => {
          console.log("Tags inside component", tags);
          const filterClick = () => {
            const tagsCopy = [...tagFilters];
            const tag = tagsCopy[i];
            tag.active = !tag.active;
            filterByTag(tag.name);
          };
          return (
            <button
              key={i}
              onClick={e => filterClick()}
              className={`tag ${tag.active ? "active" : ""}`}
            >
              {tag.label}
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <Container className="blog__container">
      {/* <Helmet script={[]} /> */}
      {renderFeaturePost()}
      {renderTagFilters()}
      <div className="grid" ref={gridRef}>
        <div className="grid-sizer"></div>
        <div className="items-sizer"></div>
        {loadedPosts && loadedPosts?.length ? (
          loadedPosts.map(({ node }, i) => (
            <BlogItem {...node} index={i} key={i} />
          ))
        ) : (
          <NoPostsSign />
        )}
      </div>
      {/* <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <PrimaryButton onClick={() => loadMorePosts()}>
              Load More
            </PrimaryButton>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
}

export function NoPostsSign() {
  return <div>No Posts To Show Here</div>;
}

export default blogListing;
