import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import BlogListing from "../components/blog/blog-listing";
import { Footer } from "../components/global/footer";
import LayoutCaseLanding from "../components/layouts/layout-case-landing";
import { QuoteBlock } from "../components/shared/quote-block";
import SEO from "../components/shared/seo";
import BackgroundImage from "gatsby-background-image";
import { FadeUp } from "../components/shared/transitions";

function blog(props) {
  const { data, location } = props;
  const { blogListingBanner } = data;
  const blogPosts = data.allPost.edges;
  const tags = data.tagNodes.edges;
  const [animate, setAnimate] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 200);
    // console.log(splitTitle(title));
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <SEO
        title="Our Work"
        keywords="Diviv, our ,work ,case, studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />

      <div className="page-layout--blog" data-menu-class="inverted">
        <BackgroundImage
          fluid={blogListingBanner.childImageSharp.fluid}
          className="fixed-background"
        />
        <section className="introduction">
          <Container>
            <Row>
              <Col sm={12} md={12} lg={10} xl={8}>
                <div style={{ overflow: "hidden" }}>
                  <h6>
                    <FadeUp pose={animate ? "visible" : "hidden"} delay={300}>
                      Blog
                    </FadeUp>
                  </h6>
                </div>
                <div style={{ overflow: "hidden" }}>
                  <h2>
                    <FadeUp pose={animate ? "visible" : "hidden"} delay={300}>
                      FEATURED POST
                    </FadeUp>
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <BlogListing
          blogPosts={blogPosts}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          tags={tags}
        />
      </div>
      <Footer />
    </>
  );
}

export default blog;

export const query = graphql`
  {
    blogListingBanner: file(
      relativePath: { eq: "hero-images-blog-listing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          slug
          title
          postType
          thumbnail {
            childImageSharp {
              fluid {
                src
                srcSet
                base64
              }
            }
          }
          author {
            name
            title
            thumbnail
          }
          date
          seo {
            descriptionMeta
            keywordsMeta
            summaryMeta
            titleMeta
          }
          tags
          body
          excerpt(pruneLength: 150)
        }
      }
    }

    tagNodes: allFile(filter: { sourceInstanceName: { eq: "tags" } }) {
      edges {
        node {
          name
        }
      }
    }
  }
`;
